import React, { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";

const AppBar: FunctionComponent<PropsWithChildren<{}>> = props => {
  const { children } = props;

  return <AppBarContainer className="AppBar">{children}</AppBarContainer>;
};

export default AppBar;

const AppBarContainer = styled.header`
  display: flex;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2px 1px var(--shadow-color-darker);
  z-index: 100;
  background-color: white;

  button {
    margin: auto 16px;
    z-index: 103;
  }
`;

const AppBarTitleContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const AppBarTitleHeading = styled.h2`
  margin: auto;
  text-align: center;
  font-family: "Product Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--text-primary-color);
  z-index: 102;
`;

interface IAppBarTitle {
  tabIndex?: number;
}

export const AppBarTitle: FunctionComponent<IAppBarTitle> = ({
  children,
  tabIndex,
}) => {
  return (
    <AppBarTitleContainer>
      <AppBarTitleHeading tabIndex={tabIndex}>{children}</AppBarTitleHeading>
    </AppBarTitleContainer>
  );
};
