import React from "react";
import { Router } from "@reach/router";

//
import "./App.css";
import Loading from "./components/Loading";
import Main from "./pages/Main.page";

const NewBudgetPage = React.lazy(() => import("./pages/NewBudget.page"));
const Budget = React.lazy(() => import("./pages/Budget.page"));
const NewTransaction = React.lazy(() => import("./pages/NewTransaction.page"));
const Transaction = React.lazy(() => import("./pages/Transaction.page"));
const ShoppingList = React.lazy(() => import("./pages/ShoppingList.page"));
const NewShoppingItem = React.lazy(() =>
  import("./pages/NewShoppingItem.page"),
);
const EditShoppingItem = React.lazy(() =>
  import("./pages/EditShoppingItem.page"),
);

function App() {
  return (
    <div className="App">
      <React.Suspense
        fallback={<Loading />}
      >
        <Router>
          <Main path="/" />
          <ShoppingList path="/shopping-list" />
          <NewShoppingItem path="/shopping-list/new" />
          <EditShoppingItem path="/shopping-list/:id" />
          <NewBudgetPage path="/new-budget" />
          <Budget path="/b/:budgetId" />
          <NewTransaction path="/b/:budgetId/new-transaction" />
          <Transaction path="/b/:budgetId/t/:transactionId" />
        </Router>
      </React.Suspense>
    </div>
  );
}

export default React.memo(App);
