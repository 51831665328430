import React, { FunctionComponent } from "react";
import styled, { keyframes } from "styled-components";

interface ILoadingProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Loading: FunctionComponent<ILoadingProps> = props => {
  const { className, style } = props;
  const arr = [null, null, null, null, null];

  return (
    <Spinner className={`Loading ${className}`} style={style}>
      {arr.map((v, i) => (
        <Rect key={i} index={i} />
      ))}
    </Spinner>
  );
};

export default Loading;

const stretchdelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`;

const Spinner = styled.div`
  margin: auto;
  /* width: 50px; */
  height: 56px;
  text-align: center;
  font-size: 10px;
`;

const Rect = styled.div<{ index: number }>`
  background-color: var(--accent-color);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 2px;
  border-radius: 8px;

  -webkit-animation: ${stretchdelay} 1.2s infinite ease-in-out;
  animation: ${stretchdelay} 1.2s infinite ease-in-out;

  -webkit-animation-delay: ${({ index: i }) => -1200 + i * 100}ms;
  animation-delay: ${({ index: i }) => -1200 + i * 100}ms;
`;
