import React, { FunctionComponent } from "react";
import styled from "styled-components";
//
import { BudgetListItem } from "./BudgetListItem";
import Flex from "../../components/Flex";
import { createResource } from "../../helpers/createResource";
import { BudgetModel } from "../../schemas";

interface IBudgetListProps {
  tabIndex?: number;
  className?: string;
  style?: React.CSSProperties;
}

const allBudgets = createResource(() =>
  BudgetModel.getSeveral({
    orderBy: {
      fieldName: "created_at",
      direction: "desc",
    },
  }),
);

export const BudgetList: FunctionComponent<IBudgetListProps> = props => {
  const { tabIndex, className, style } = props;

  const budgets = allBudgets.read();

  return (
    <Flex column>
      {budgets.length === 0 && (
        <div style={{ margin: "auto" }}>Theres no budgets</div>
      )}
      {budgets.length > 0 && (
        <ContainerUL className={className} style={style}>
          {budgets.map((transaction, index) => {
            return (
              <BudgetListItem
                key={transaction.id}
                {...transaction}
                itemStyle={{ margin: "-1px 0 0" }}
                tabIndex={tabIndex + index}
              />
            );
          })}
        </ContainerUL>
      )}
    </Flex>
  );
};

const ContainerUL = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 0 32px;
`;
