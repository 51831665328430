import { Schema, type } from "firestore-schema";

export const ShoppingListSchema = new Schema<IToBuy>("shopping_list", {
  ["item_name"]: type.String({ required: true }),
  order: type.Number,
});

export interface IToBuy {
  item_name: string;
  order?: number;
}
