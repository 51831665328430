import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
//
import { BudgetList as AllBudgets } from "../containers/BudgetList";
import Page from "../components/Page";
import AppBar, { AppBarTitle } from "../components/AppBar";
import Flex from "../components/Flex";
import IconButton from "../components/IconButton";
import Loading from "../components/Loading";

const Main: FunctionComponent<RouteComponentProps> = function Main(props) {
  const { navigate } = props;

  const openNewBudgetDialog = () => {
    navigate("/new-budget");
  };

  const navigateToList = () => {
    navigate("/shopping-list");
  };

  return (
    <Page style={{ paddingTop: 72 }}>
      <AppBar>
        <AppBarTitle tabIndex={1}>Your Budgets</AppBarTitle>
        <IconButton
          iconName="list"
          onClick={navigateToList}
          tabIndex={2}
          ariaLabel="Shopping List"
          style={{ margin: "auto 16px auto auto" }}
        />
      </AppBar>

      <Flex column>
        <React.Suspense fallback={<Loading />}>
          <AllBudgets tabIndex={4} />
          <IconButton
            iconName="add"
            onClick={openNewBudgetDialog}
            tabIndex={3}
          />
        </React.Suspense>
      </Flex>
    </Page>
  );
};

export default React.memo(Main);
