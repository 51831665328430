import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import FirestoreSchema from "firestore-schema";
import { OneToMany } from "firestore-schema/src/relationships/OneToMany";
import { Formula } from "firestore-schema/src/relationships/Formula";
import { BudgetSchema } from "./budget.schema";
import { TransactionSchema } from "./transaction.schema";
import { ShoppingListSchema } from "./shopping-list.schema";

const firebaseApp1 = firebase.initializeApp({
  apiKey: "AIzaSyCt2isUz7z9ZAL8lIYkA5KE9xagu-CIksY",
  authDomain: "maxizipitria-37315.firebaseapp.com",
  databaseURL: "https://maxizipitria-37315.firebaseio.com",
  projectId: "maxizipitria-37315",
  storageBucket: "maxizipitria-37315.appspot.com",
  messagingSenderId: "599065177114",
});

const App1 = FirestoreSchema.initialize(firebaseApp1);
export const BudgetModel = App1.define(BudgetSchema);
export const TransactionModel = App1.define(TransactionSchema);
export const ShoppingListModel = App1.define(ShoppingListSchema);

OneToMany(
  BudgetModel.fields.transactions as any,
  TransactionModel.fields.budget as any,
);
Formula(
  TransactionModel.fields.budget as any,
  TransactionModel.fields.amount as any,
  BudgetModel.fields.total_expenses as any,
);
