import React from "react";

const SLOWDOWN_RATE = 0.4;
const IS_MOBILE = true;
const ON_POINTER_MOVE = IS_MOBILE ? "touchmove" : "mousemove";
const ON_POINTER_RELEASE = IS_MOBILE ? "touchend" : "mouseup";

type PointerEvent = React.MouseEvent | React.TouchEvent;

interface IDraggingEvents {
  onMouseDown: (ev: React.MouseEvent) => void;
  onTouchStart: (ev: React.TouchEvent) => void;
}

export function useDragging(
  divElementRef: React.MutableRefObject<HTMLDivElement>,
  thresholdPoint: number,
  releasePoint: number,
) {
  const [isDragging, setDragging] = React.useState(false);
  const [initialMousePosition, setInitialMousePosition] = React.useState<
    number[]
  >(null);
  const [draggingOffset, setDraggingOffset] = React.useState({
    left: 0,
    right: 0,
  });
  const [elementOffset, setElementOffset] = React.useState({
    left: 0,
    right: 0,
  });

  const onPress = (e: PointerEvent) => {
    if (isDragging && initialMousePosition) {
      return;
    }

    if (!IS_MOBILE) {
      e.preventDefault();
    }

    setDragging(true);
    setInitialMousePosition(getPointerCoords(e));
  };

  const onMove = React.useCallback(
    (e: PointerEvent) => {
      if (!isDragging) {
        return;
      }

      const currentMousePosition = getPointerCoords(e);
      const diff = [
        (currentMousePosition[0] - initialMousePosition[0]) * SLOWDOWN_RATE,
        (currentMousePosition[1] - initialMousePosition[1]) * SLOWDOWN_RATE,
      ];

      if (diff[0] > -10 && diff[0] < 10) {
        return;
      }

      setDraggingOffset({
        left: diff[0],
        right: diff[0] * -1,
      });
    },
    [isDragging, initialMousePosition],
  );

  const onRelease = React.useCallback(
    (e: PointerEvent) => {
      if (!isDragging || !initialMousePosition) {
        return;
      }

      if (!IS_MOBILE) {
        e.preventDefault();
      }

      setDragging(false);
      setInitialMousePosition(null);
    },
    [isDragging, initialMousePosition],
  );

  ///////////////////
  // SIDE EFFFECTS
  ///////////////////
  /**
   * When dragmode is enabled, adds 2 events to the window.
   */
  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener(ON_POINTER_MOVE, onMove as any);
      window.addEventListener(ON_POINTER_RELEASE, onRelease as any);
    }

    return () => {
      if (isDragging) {
        window.removeEventListener(ON_POINTER_MOVE, onMove as any);
        window.removeEventListener(ON_POINTER_RELEASE, onRelease as any);
      }
    };
  }, [isDragging, onMove, onRelease]);

  /**
   * Updates the referenced element's style when the element is being dragged.
   */
  React.useEffect(() => {
    if (!divElementRef.current) {
      return;
    }

    const left = draggingOffset.left + elementOffset.left;
    const right = draggingOffset.right + elementOffset.right;

    divElementRef.current.parentElement.style.overflow = "hidden";
    divElementRef.current.style.marginLeft = left + "px";
    divElementRef.current.style.marginRight = right + "px";
  }, [
    divElementRef,
    draggingOffset.left,
    elementOffset.left,
    draggingOffset.right,
    elementOffset.right,
  ]);

  /**
   * Decides where to lock the offset position.
   */
  React.useEffect(() => {
    if (isDragging) {
      return;
    }

    let _elementOffset;
    if (elementOffset.left + draggingOffset.left < thresholdPoint) {
      _elementOffset = {
        left: thresholdPoint,
        right: thresholdPoint * -1,
      };
    } else if (elementOffset.left + draggingOffset.left > releasePoint) {
      _elementOffset = {
        left: 0,
        right: 0,
      };
    }

    setDraggingOffset({ left: 0, right: 0 });

    if (_elementOffset) {
      setElementOffset(_elementOffset);
    }
  }, [
    draggingOffset.left,
    elementOffset.left,
    isDragging,
    releasePoint,
    thresholdPoint,
  ]);

  return {
    onMouseDown: onPress,
    onTouchStart: onPress,
  } as IDraggingEvents;
}

function isMobile(ev: PointerEvent): ev is React.TouchEvent {
  if (!(ev as React.TouchEvent).touches) {
    return false;
  }

  return IS_MOBILE;
}

function getPointerCoords(e: PointerEvent) {
  if (isMobile(e)) {
    return [e.touches[0].screenX, e.touches[0].screenY];
  } else {
    return [e.screenX, e.screenY];
  }
}
