import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface IBalanceProps {
  label: string;
  value: number;
}

export const Balance: FunctionComponent<IBalanceProps> = props => {
  const { label, value } = props;

  return (
    <Container className="Balance">
      <div className="label">
        <i className="material-icons">attach_money</i>
        <span>{label}</span>
      </div>
      <div className="value">{value}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: 2px solid #007769;
  border-radius: 4px;

  .label {
    display: flex;
    width: 80px;
    text-align: center;
    background-color: #007769;
    color: white;

    i.material-icons {
      margin: auto 0 auto auto;
      font-size: 16px;
    }

    span {
      margin: auto auto auto 0;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .value {
    width: 80px;
    padding: 4px 12px 4px 12px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    color: #007769;
  }
`;
