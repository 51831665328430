"use strict";

var _classCallCheck = require("C:\\Users\\maxiz\\Documents\\projects\\budget-manager\\node_modules\\@babel\\runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var SchemaField = function SchemaField(args) {
  _classCallCheck(this, SchemaField);

  this.args = args;
};

exports.SchemaField = SchemaField;