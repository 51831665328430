import { Link } from "@reach/router";
import * as React from "react";
import styled from "styled-components";
import { Balance } from "./Balance";
import { useDragging as useSwipe } from "./useDragging";
import Flex from "../../components/Flex";
import IconButton from "../../components/IconButton";

export const BudgetListItem: React.FunctionComponent<any> = function(props) {
  const { id, month, active, tabIndex } = props;
  let {
    starting_balance: startingBalance,
    total_incomes: totalIncomes,
    total_expenses: totalExpenses,
  } = props;

  const draggableElementRef = React.useRef(null);
  const { onMouseDown: mouseDownHandler, onTouchStart } = useSwipe(
    draggableElementRef,
    -60,
    -40,
  );

  startingBalance = Math.round(startingBalance || 0);
  totalIncomes = Math.round(totalIncomes || 0);
  totalExpenses = Math.round(totalExpenses || 0);
  const finalBalance = startingBalance + totalIncomes - totalExpenses;

  return (
    <Container active={active || false} >
      <div className="draglayer draglayer__delete">
        <IconButton
          iconName="delete"
          style={{ margin: "auto 16px auto auto", color: "white" }}
        />
      </div>
      {/* <div
        className="content-layer"
        onMouseDown={mouseDownHandler}
        onTouchStart={onTouchStart}
        ref={draggableElementRef}
      > */}
      <Link
        to={`/b/${id}`}
        className="draglayer draglayer__content"
        onMouseDown={mouseDownHandler}
        onTouchStart={onTouchStart}
        ref={draggableElementRef}
        tabIndex={tabIndex}
      >
        <Icon>
          <i className="material-icons">calendar_today</i>
        </Icon>

        <Flex column className="details">
          <Month>{month}</Month>

          <Flex column style={{ margin: "8px 0 0" }}>
            <IncomesExpenses incomes>
              <i className="material-icons">arrow_forward</i>
              <p>$ {totalIncomes}</p>
            </IncomesExpenses>
            <IncomesExpenses expenses>
              <i className="material-icons">arrow_back</i>
              <p>$ {totalExpenses}</p>
            </IncomesExpenses>
          </Flex>
        </Flex>

        <Flex column className="balances">
          <Balance label="Initial" value={startingBalance} />
          <Balance label="Final" value={finalBalance} />
        </Flex>
      </Link>
      {/* </div> */}
    </Container>
  );
};

const Container = styled.li<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: move;
  transition: all;


  > a:focus {
    z-index: 20;
  }

  .draglayer {
    height: 110px;
    display: flex;

    * {
      user-select: none;
    }

    &__delete {
      background-color: #d32f2f;
      border: 1px solid white;
    }

    &__content {
      position: absolute;
      width: 100%;
      background-color: white;
      border-top: 1px solid
        ${props => (props.active ? `#c11c6177` : `rgba(0, 0, 0, 0.08)`)};
      border-bottom: 1px solid
        ${props => (props.active ? `#c11c6177` : `rgba(0, 0, 0, 0.08)`)};
      text-decoration: none;
      color: #000000f0;

      .details {
        padding: 16px 0;
        flex: 1;
      }

      .balances {
        justify-content: space-around;
        padding: 16px;
      }
    }
  }
`;

const Icon = styled.div`
  height: 48px;
  width: 48px;
  margin: auto 16px;
  border-radius: 50%;
  line-height: 58px;
  text-align: center;
  background-color: #f5f5f9;
  color: rgba(0, 0, 0, 0.64);
`;

const Month = styled.h3`
  margin: 4px 0 0;
  font-size: 18px;
  letter-spacing: 1px;
  color: #000000bf;
`;

const IncomesExpenses = styled.div<any>`
  display: flex;
  margin: 4px 0 0;

  i.material-icons {
    font-size: 14px;
    ${props => props.incomes && `color: green;`}
    ${props => props.expenses && `color: red;`}
  }

  p {
    margin: 0 0 0 4px;
    font-size: 14px;
  }
`;
