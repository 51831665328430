import { Schema, type } from "firestore-schema";

export const BudgetSchema = new Schema<IBudget>("budget", {
  month: type.String,
  "starting_balance": type.Number({ defaultValue: 0 }),
  "total_incomes": type.Number({ defaultValue: 0 }),
  "total_expenses": type.Number({ defaultValue: 0 }),
  transactions: type.Collection({ schema: "transactions", defaultValue: [] })
});

export interface IBudget {
  month: string;
  starting_balance?: number;
  total_incomes?: number;
  total_expenses?: number;
  transactions?: string[];
  sum?: number;
}
