"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function validatePrimitiveType(fieldTypeName, fieldContext, fieldName, value) {
  var valueType = typeof value;
  var isValid = fieldTypeName === valueType;

  if (!isValid) {
    throw new Error("Invalid type in field '".concat(fieldContext.model.name, ".").concat(fieldName, " (").concat(fieldTypeName, ")' with value '").concat(value, "' (").concat(valueType, ")"));
  }

  return true;
}

exports.validatePrimitiveType = validatePrimitiveType;