"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var onUpdate_1 = require("./onUpdate");
/**
 * Every time the list/collection field is updated, the size of the array will be saved on a different field
 *
 * @param arrayField
 * @param numberField
 */


function ArraySize(arrayField, numberField) {
  //
  // This relation only work if both fields are on the same schema
  // Research how to do on different schemas
  //  - The problem is that we dont know which document (document id) has the numberField
  //
  if (arrayField.context.model.name !== numberField.context.model.name) {
    throw new Error("Both fields should be on the same schema");
  }

  var store = arrayField.context.app.store;
  store.setSchemaEvents(arrayField.context.model.name, {
    onUpdate: onUpdate_1.onUpdate(arrayField, numberField)
  });
}

exports.ArraySize = ArraySize;