import * as React from "react";
import styled from "styled-components";
import mediaQueries from "../helpers/mediaQueries";
import Flex from "./Flex";

function Page(props) {
  const { className, style, innerStyle, children } = props;
  return (
    <OutterPage
      className={`${className || ""} OutterPage`}
      column
      style={style}
    >
      <InnerPage className="InnerPage" column style={innerStyle}>
        {children}
      </InnerPage>
    </OutterPage>
  );
}

const OutterPage = styled(Flex)`
  width: 100vw;
  max-width: 100vw;
  background-color: #f6f7f9;
`;

const InnerPage = styled(Flex)`
  min-height: 100vh;
  box-sizing: border-box;

  ${mediaQueries.desktop`
    width: 50%;
    margin: 0 auto;
  `}
`;

export default React.memo(Page);
