import * as React from "react";
import styled from "styled-components";

interface IFlexProps {
  column?: boolean;
  m?: number;
  p?: number;
  width?: number;
  onClick?: () => void;
  children?: React.ReactElement | React.ReactElement[];
  style?: React.CSSProperties;
  className?: string;
}

function Flex(props: IFlexProps) {
  const { className, style, column, m, p, width, children } = props;

  return (
    <FlexContainer
      className={`${className || ""} FlexBox`}
      column={column}
      margin={m ? m * 16 : 0}
      padding={p ? p * 16 : 0}
      width={width ? (width < 1 ? width * 100 : width) : null}
      style={style}
    >
      {children}
    </FlexContainer>
  );
}

const FlexContainer = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.column ? "column" : "row")};
  position: relative;
  padding: ${props => props.padding}px;
  margin: ${props => props.margin}px;
  ${props => props.width && "width: " + props.width + "%"};
`;

export default Flex;
