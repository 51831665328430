import { css, CSSObject, SimpleInterpolation } from "styled-components";

function createMediaQuery(size: number) {
  return (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]) => css`
    @media (min-width: ${size / 16}em) {
      ${css(first, ...interpolations)}
    }
  `;
}

const mediaQueries = {
  phone: createMediaQuery(400),
  tablet: createMediaQuery(768),
  desktop: createMediaQuery(1024)
};

export default mediaQueries;
