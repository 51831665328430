import { Schema, type } from "firestore-schema";

export const incomeCategories = ["Salary", "Loan"];
export const expenseCategories = [
  "Job",
  "Food",
  "Home",
  "Grocery",
  "Personal",
  "Clothing",
  "Goods",
  "Pets",
  "Transport",
  "Bills",
  "Rent",
  "Credit Card",
  "Other",
  "Cash Out"
];
export const categories = [...incomeCategories, ...expenseCategories];

export const TransactionSchema = new Schema<ITransaction>("transactions", {
  budget: type.LookUp({ schema: "budget", required: true }),
  type: type.OneOf({
    validValues: ["expense", "income"],
    defaultValue: "expense"
  }),
  category: type.OneOf({
    validValues: categories,
    defaultValue: categories[0]
  }),
  amount: type.Number,
  description: type.String
});

export interface ITransaction {
  budget: string;
  type: "expense" | "income";
  category: string;
  amount: number;
  description?: string;
}
