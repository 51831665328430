import React, { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";

interface IIconButtonProps {
  iconName: string;
  className?: string;
  disabled?: boolean;
  onClick?: (event) => void;
  ariaLabel?: string;
  tabIndex?: number;
  style?: React.CSSProperties;
}

const IconButton: FunctionComponent<
  PropsWithChildren<IIconButtonProps>
> = props => {
  const {
    iconName,
    className,
    disabled,
    onClick,
    tabIndex,
    ariaLabel,
    style,
  } = props;

  return (
    <IconButtonContainer
      className={className}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      style={style}
    >
      <i className="material-icons">{iconName}</i>
    </IconButtonContainer>
  );
};

export default IconButton;

const IconButtonContainer = styled.button`
  border: 0;
  padding-top: 2px;
  background-color: transparent;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.64);

  &.left {
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
    color: var(--text-secondary-color);
  }
`;
