"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isNull(value) {
  return value === null || value === undefined || typeof value === "string" && value === "";
}

exports.isNull = isNull;

function intersection(arr1, arr2) {
  return arr1.filter(function (value) {
    return arr2.indexOf(value) !== -1;
  });
}

exports.intersection = intersection;