"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function createCache() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var MAX_TIME_IN_SECONDS = options.time || 200;
  var collections = {};
  var lastUpdate = {};

  function saveDocument(documentPath, data) {
    var _parseDocumentPath = parseDocumentPath(documentPath),
        collection = _parseDocumentPath.collection,
        document = _parseDocumentPath.document;

    if (!collections[collection]) {
      collections[collection] = {};
      lastUpdate[collection] = {};
    }

    collections[collection][document] = data;
    lastUpdate[collection][document] = Date.now();
  }

  function removeDocument(documentPath) {
    var _parseDocumentPath2 = parseDocumentPath(documentPath),
        collection = _parseDocumentPath2.collection,
        document = _parseDocumentPath2.document;

    if (collections[collection] && collections[collection][document]) {
      collections[collection][document] = null;
      lastUpdate[collection][document] = null;
    }
  }

  function updateDocument(documentPath, updatedFields) {
    var _parseDocumentPath3 = parseDocumentPath(documentPath),
        collection = _parseDocumentPath3.collection,
        document = _parseDocumentPath3.document;

    if (!collections[collection] || !collections[collection][document]) {
      return;
    }

    collections[collection][document] = Object.assign({}, collections[collection][document], updatedFields);
    lastUpdate[collection][document] = Date.now();
  }

  function getDocument(collectionName, documentId) {
    var doesCollectionExist = !!collections[collectionName];

    if (!doesCollectionExist) {
      collections[collectionName] = {};
      lastUpdate[collectionName] = {};
    }

    var cachedDocument = collections[collectionName][documentId];

    if (cachedDocument) {
      var now = Date.now();
      var then = lastUpdate[collectionName][documentId];
      var diffInSeconds = (now - then) / 1000;

      if (diffInSeconds < MAX_TIME_IN_SECONDS) {
        return cachedDocument;
      }
    }

    return null;
  }

  function getCollection(collectionName) {
    var includeIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var doesCollectionExist = !!collections[collectionName];

    if (!doesCollectionExist) {
      collections[collectionName] = {};
      lastUpdate[collectionName] = {};
    }

    var collection = collections[collectionName];
    return Object.keys(collection).map(function (docId) {
      var doc = getDocument(collectionName, docId);

      if (includeIds) {
        return Object.assign({}, doc, {
          id: docId
        });
      }

      return doc;
    });
  }

  return {
    saveDocument: saveDocument,
    removeDocument: removeDocument,
    updateDocument: updateDocument,
    getDocument: getDocument,
    getCollection: getCollection
  };
}

exports.default = createCache;

function parseDocumentPath(path) {
  var pathParts = path.split("/");
  var isPathValid = pathParts.length >= 2 && pathParts.length % 2 === 0;

  if (!isPathValid) {
    throw new Error("[FirebaseWrapper] Invalid path " + path);
  }

  var document = pathParts[pathParts.length - 1];
  var collection = path.replace("/".concat(document), "");
  return {
    collection: collection,
    document: document
  };
}