"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var __1 = require("../..");

var helpers_1 = require("./helpers");

var RelatedRecords_1 = require("./RelatedRecords");

var OneToMany_1 = require("./OneToMany");

var ArraySize_1 = require("./ArraySize");

var Formula_1 = require("./Formula");

var types_1 = require("../types");

function relatedRecords($lookUp) {
  var $relatedRecordsField = types_1.createFieldObserver();
  var relatedRecordsField = RelatedRecords_1.RelatedRecordsField({
    schema: "",
    field: "",
    readOnly: true
  }, $relatedRecordsField);
  helpers_1.onRefsDone([$lookUp, $relatedRecordsField], function (lookup, relatedRecords) {
    relatedRecords.args.schema = lookup.context.model.name;
    relatedRecords.args.field = lookup.name;
  });
  return relatedRecordsField;
}

exports.relatedRecords = relatedRecords;
/* Creates a relation (one-to-many) between 2 schemas. */

function createRelatedCollection(lookupRef) {
  var collectionRef = types_1.createFieldObserver();

  var CollectionField = __1.type.Collection({
    schema: "",
    defaultValue: [],
    readOnly: true
  }, collectionRef);

  function handleRefsDone(field1, field2) {
    field1.args.schema = field2.context.model.name;
    field1.data.lookup.args.schema = field2.context.model.name;
    field2.args.schema = field1.context.model.name;
    OneToMany_1.OneToMany(field1, field2);
  }

  helpers_1.onRefsDone([collectionRef, lookupRef], handleRefsDone);
  return CollectionField;
}

exports.createRelatedCollection = createRelatedCollection;

function createSizeField(collectionFieldRef) {
  var numberFieldRef = types_1.createFieldObserver();

  var NumberField = __1.type.Number({
    defaultValue: 0,
    readOnly: true
  }, numberFieldRef);

  function onComplete(field1, field2) {
    ArraySize_1.ArraySize(field1, field2);
  }

  helpers_1.onRefsDone([collectionFieldRef, numberFieldRef], onComplete);
  return NumberField;
}

exports.createSizeField = createSizeField;

function createSumField(numberFieldRef, unionFieldRef) {
  var fR = types_1.createFieldObserver();

  var AccumulatorField = __1.type.Number({
    defaultValue: 0,
    readOnly: true
  }, fR);

  function onComplete(field1, field2, field3) {
    Formula_1.Formula(field1, field2, field3);
  }

  helpers_1.onRefsDone([unionFieldRef, numberFieldRef, fR], onComplete);
  return AccumulatorField;
}

exports.createSumField = createSumField;