// eslint-disable-next-line @typescript-eslint/camelcase
import { unstable_createResource } from "react-cache";

interface ICreateResource {
  <A extends Array<any>, R>(promiseFn: (...args: A) => Promise<R>): { read(...args: A): R };
}

// eslint-disable-next-line @typescript-eslint/camelcase
const createResource = unstable_createResource as any as ICreateResource;

export { createResource };

// export function createResource<A extends Array<any>, R>(
//   promiseFn: (...args: A) => Promise<R>,
// ) {
//   let status = "pending";
//   let result: R;
//   let error: any;

//   return {
//     read(...args: A): R {
//       if (status === "pending") {
//         const suspender = promiseFn(...args).then(
//           r => {
//             status = "success";
//             result = r;
//           },
//           e => {
//             status = "error";
//             error = e;
//           },
//         );

//         throw suspender;
//       } else if (status === "error") {
//         throw error;
//       } else if (status === "success") {
//         return result;
//       }
//     },
//   };
// }
