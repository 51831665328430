"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var onCreate_1 = require("./onCreate");

var onDelete_1 = require("./onDelete");

var onUpdate_1 = require("./onUpdate");
/**
 * Sums up a specific field on every document an stores the result on a related document of a different schema
 *
 * @param numberField Field we want to sum
 * @param accumulator Field where the result will be stored
 * @param unionField Field located on the same schema as the first field and points to the schema on the second field
 */


function Formula(unionField, numberField, accumulator) {
  if (numberField.context.model.name !== unionField.context.model.name) {
    throw new Error("Has to be the same schema");
  }

  if (unionField.context.model.name === accumulator.context.model.name) {
    throw new Error("Has to be a different schema");
  }

  var store = numberField.context.app.store;
  store.setSchemaEvents(numberField.context.model.name, {
    onCreate: onCreate_1.onCreate(numberField, accumulator, unionField),
    onDelete: onDelete_1.onDelete(numberField, accumulator, unionField),
    onUpdate: onUpdate_1.onUpdate(numberField, accumulator, unionField)
  });
}

exports.Formula = Formula;