"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 *
 * @param fieldRefs
 * @param onComplete
 */

function onRefsDone(fieldRefs, onComplete) {
  var allSchemaFields = [];

  var getSubscriber = function getSubscriber(index) {
    return function subscriber(schemaField) {
      allSchemaFields[index] = schemaField;
      var allDone = allSchemaFields.length === fieldRefs.length;

      if (allDone) {
        onComplete.apply(void 0, allSchemaFields);
      }
    };
  };

  for (var i = 0; i < fieldRefs.length; i++) {
    var fr = fieldRefs[i];
    fr.subscribe(getSubscriber(i));
  }
}

exports.onRefsDone = onRefsDone;