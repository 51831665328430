"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function createStore() {
  var names = [];
  var schemas = {};
  var schemaEvents = {};

  function checkIsDefined(name) {
    return names.indexOf(name) !== -1;
  }

  function define(name, schema, events) {
    var isDefined = checkIsDefined(name);

    if (!isDefined) {
      names.push(name);
      schemas[name] = schema;
      events && setSchemaEvents(name, events);
    }
  }

  function removeDefinition(schemaName) {
    var isDefined = checkIsDefined(schemaName);

    if (isDefined) {
      names = names.filter(function (n) {
        return n !== schemaName;
      });
      delete schemas[schemaName];
      delete schemaEvents[schemaName];
    }
  }

  function setSchemaEvents(schemaName, events) {
    if (!schemaEvents[schemaName]) {
      schemaEvents[schemaName] = {
        onCreate: [],
        onUpdate: [],
        onDelete: []
      };
    }

    var onCreate = events.onCreate,
        onDelete = events.onDelete,
        onUpdate = events.onUpdate;
    if (onCreate) schemaEvents[schemaName].onCreate.push(onCreate);
    if (onDelete) schemaEvents[schemaName].onDelete.push(onDelete);
    if (onUpdate) schemaEvents[schemaName].onUpdate.push(onUpdate);
  }

  var store = {
    isDefined: checkIsDefined,
    define: define,
    removeDefinition: removeDefinition,
    getSchema: function getSchema(schemaName) {
      return schemas[schemaName];
    },
    getSchemaEvents: function getSchemaEvents(schemaName) {
      return schemaEvents[schemaName];
    },
    setSchemaEvents: setSchemaEvents
  };
  return store;
}

exports.default = createStore;