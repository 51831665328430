"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function parse(keys) {
  // console.log("keys: ", keys);
  var r = {};
  var indentLevel = 0;

  for (var index = 0; index < keys.length; index++) {
    var key = keys[index]; // console.log("current key: ", key);

    if (key === "{") {
      indentLevel++;

      if (indentLevel === 1) {
        var remainingKeys = keys.slice(index + 1);
        r[keys[index - 1]] = parse(remainingKeys);
      }
    } //
    else if (key === "}") {
        if (indentLevel === 0) {
          return r;
        }

        indentLevel--;
      } //
      else {
          if (indentLevel === 0) {
            r[key] = true;
          }
        }
  }

  return r;
} // TODO: Join the 2 arguments into one string


function queryTag(strings) {
  var woN = strings.map(function (s) {
    return s.replace(/,|\n|:/g, "");
  });
  var keys = woN[0].split(" ").filter(function (s) {
    return s !== "";
  });
  var fields = parse(keys);
  return fields;
}

exports.queryTag = queryTag;