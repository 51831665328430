"use strict";

var _slicedToArray = require("C:\\Users\\maxiz\\Documents\\projects\\budget-manager\\node_modules\\@babel\\runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var src_1 = require("./src");

exports.createFieldType = src_1.createType;
exports.type = src_1.defaultTypes;
exports.Schema = src_1.Schema;
exports.SchemaFieldType = src_1.SchemaFieldType;

var Firebase = __importStar(require("./src/firebase"));

var relationships_1 = require("./src/relationships");

exports.relatedRecords = relationships_1.relatedRecords;
exports.createRelatedCollection = relationships_1.createRelatedCollection;
exports.createSumField = relationships_1.createSumField;
exports.createSizeField = relationships_1.createSizeField;

var types_1 = require("./src/types");

exports.createFieldObserver = types_1.createFieldObserver;

var queryTag_1 = require("./src/helpers/queryTag");

exports.queryTag = queryTag_1.queryTag;

function initialize(firebaseApp) {
  if (!firebaseApp) {
    throw new Error("You need to pass a valid firebase app instance.");
  }

  var _Firebase$setup = Firebase.setup(firebaseApp),
      _Firebase$setup2 = _slicedToArray(_Firebase$setup, 2),
      auth = _Firebase$setup2[0],
      firebaseHelpers = _Firebase$setup2[1];

  var app = {
    api: firebaseHelpers,
    store: src_1.createStore()
  };

  function define(schema) {
    var model;

    if (app.store.isDefined(schema.name)) {
      model = app.store.getSchema(schema.name);
    } else {
      model = new src_1.Model(app, schema);
    }

    return model;
  }

  function undefine(schemaName) {
    app.store.removeDefinition(schemaName);
  }

  var getSchema = function getSchema(schemaName) {
    return app.store.getSchema(schemaName);
  };

  return {
    define: define,
    undefine: undefine,
    getSchema: getSchema,
    Login: auth && auth.Login,
    SignUp: auth && auth.SignUp,
    Logout: auth && auth.Logout,
    getCurrentUserId: auth && auth.getCurrentUserId,
    onSessionChange: auth && auth.onSessionChange
  };
}

exports.default = {
  initialize: initialize
};